import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import TokenService from "../Services/token.service";

const PrivateRoute = ({ component: Component, actionName, ...rest }) => {
  const currentUser = TokenService.getLocalAccessToken();
  const user = TokenService.getUserData();
  if (!currentUser) {
    return (
      <Navigate
        to={{ pathname: "/auth/login", state: { from: rest.location } }}
      />
    );
  }

  // if the pathname is /home and the user is not an admin, redirect to /dashboard
  const rolePaths = {
    sales: "/contract",
    saleshead: "/contract",
    finance: "/contract",
  };

  const path = window.location.pathname;
  if (path === "/home" && user && rolePaths[user?.role]) {
    return <Navigate to={rolePaths[user?.role]} />;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...rest} />
    </Suspense>
  );
};

export default PrivateRoute;
