import { lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

const AssessmentReportClassProfile = lazy(() =>
  import("../Modules/Public/AssessmentReport/classProfile")
);
const AssessmentReportStudent = lazy(() =>
  import("../Modules/Public/AssessmentReport/student")
);
const DashboardLayout = lazy(() => import("../Modules/Dashboard/Dashboard"));
const LoginLayout = lazy(() =>
  import("../Modules/Auth/LoginScreen/LoginScreen")
);
const HomeLayout = lazy(() => import("../Modules/Dashboard/Home/Home"));
const SchoolsLayout = lazy(() =>
  import("../Modules/Dashboard/Schools/SchoolDashboard")
);
const BatchDashboardLayout = lazy(() =>
  import("../Modules/Dashboard/BatchDashboard/BatchDashboard")
);
const AssessmentDashboardLayout = lazy(() =>
  import("../Modules/Dashboard/AssessmentsDashboard/index.jsx")
);
const AdhocLessonAccess = lazy(() =>
  import("../Modules/Public/AdhocLessonAccess")
);
const SignReport = lazy(() => import("../Modules/Public/SignReport"));
const UploadContract = lazy(() => import("../Modules/Public/UploadContract"));
const AdhocAccessLinkGeneratorLayout = lazy(() =>
  import(
    "../Modules/Dashboard/AdhocAccessLinkGenerator/AdhocAccessLinkGenerator.js"
  )
);
const ComplaintsLayout = lazy(() =>
  import("../Modules/Dashboard/Complaints/Complaints")
);
const IssuesLayout = lazy(() => import("../Modules/Dashboard/Issues/Issues"));
const SchoolLayout = lazy(() => import("../Modules/Dashboard/School/School"));
const TeacherLayout = lazy(() =>
  import("../Modules/Dashboard/Teacher/Teacher")
);
const NotFoundLayout = lazy(() =>
  import("../Modules/Dashboard/NotFound/NotFound")
);

const SalesSchools = lazy(() =>
  import("../Modules/SalesDashboard/Schools/Schools")
);

const Faqs = lazy(() => import("../Modules/FAQ/Faq.jsx"));

const FreeSpeakingReport = lazy(() =>
  import("../Modules/Public/FreeSpakingReport")
);

export const routes = ({ user, showSuccess, showInfo, showError }) => [
  {
    path: "/auth",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Navigate to="/auth/login" />,
      },
      {
        path: "login",
        element: (
          <LoginLayout
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
          />
        ),
      },
    ],
  },
  {
    path: "/",
    element:
      user && user !== null ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/auth/login" />
      ),
    children: [
      {
        path: "home",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={HomeLayout}
          />
        ),
      },
      {
        path: "schools",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={SchoolsLayout}
          />
        ),
      },
      {
        path: "batches",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={BatchDashboardLayout}
          />
        ),
      },
      {
        path: "assessments",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={AssessmentDashboardLayout}
          />
        ),
      },
      {
        path: "school/:id",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={SchoolLayout}
          />
        ),
      },
      {
        path: "teacher/:teacherId",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={TeacherLayout}
          />
        ),
      },
      {
        path: "adhocAccessLinkGenerator",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={AdhocAccessLinkGeneratorLayout}
          />
        ),
      },
      {
        path: "issues",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={IssuesLayout}
          />
        ),
      },
      {
        path: "complaints",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={ComplaintsLayout}
          />
        ),
      },

      {
        path: "contract",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={SalesSchools}
          />
        ),
      },
      {
        path: "faq",
        element: (
          <PrivateRoute
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
            component={Faqs}
          />
        ),
      },
      {
        path: "*",
        element: <PrivateRoute component={NotFoundLayout} />,
      },
    ],
  },
  {
    // Temp Lesson Access
    path: "/tla/:accessCode",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <AdhocLessonAccess />,
      },
    ],
  },
  // Sign Report
  {
    path: "/signReport/:scheduleId",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <SignReport showError={showError} />,
      },
    ],
  },
  {
    path: "/uploadContract",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <UploadContract />,
      },
    ],
  },
  {
    path: "/assessmentReport/classProfile/:classProfileId",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <AssessmentReportClassProfile
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
          />
        ),
      },
    ],
  },
  {
    path: "/assessmentReport/student/:encryptedData",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
          <AssessmentReportStudent
            showSuccess={showSuccess}
            showInfo={showInfo}
            showError={showError}
          />
        ),
      },
    ],
  },
  {
    path: "/freeSpeakingReport/:schoolId",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <FreeSpeakingReport />,
      },
    ],
  },
];
