const TokenService = {
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"))?.tokens;
    return user?.token;
  },

  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"))?.tokens;
    return user?.refresh;
  },

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.tokens.token = token;
    localStorage.setItem("user", JSON.stringify(user));
  },

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  },

  getUserData() {
    return JSON.parse(localStorage.getItem("user-data"));
  },

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  },

  removeUser() {
    localStorage.removeItem("user");
  },
};

export default TokenService;
