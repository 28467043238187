import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  actions: [],
  name: "",
  events: [],
  pms: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addActions(state, action) {
      state.actions = action.payload?.actions || [];
      state.name = action.payload?.name || "";
    },
    resetState(state, action) {
      state = initialState;
    },
    addEvents(state, action) {
      state.events = action.payload;
    },
    addPms(state, action) {
      state.pms = action.payload;
    },
    addUser(state, action) {
      state.user = action.payload;
    },
  },
});

export const { addActions, resetState, addEvents, addPms, addUser } =
  userSlice.actions;

export default userSlice.reducer;
