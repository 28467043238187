import "./App.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./Routes/Routes";
import { Suspense } from "react";
import TokenService from "./Services/token.service";
import toast, { Toaster } from "react-hot-toast";

function App() {
  const user = TokenService.getUser() || null;

  const showSuccess = (value) => {
    toast.success(
      <div>
        {value.message && <h5>{value.message}</h5>}
        {value.detail && <span>{value.detail}</span>}
      </div>
    );
  };

  const showInfo = (value) => {
    toast(
      <div>
        {value.message && <h5>{value.message}</h5>}
        {value.detail && <span>{value.detail}</span>}
      </div>,
      {
        icon: "ℹ",
      }
    );
  };

  const showError = (value) => {
    toast.error(
      <div>
        {value.message && <h5>{value.message}</h5>}
        {value.detail && <span>{value.detail}</span>}
      </div>
    );
  };

  return (
    <>
      {/* Added new Cool looking toaster for showing notifications */}
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: "#E2ECF6",
          },
          success: {
            style: {
              background: "#E2F6E2",
            },
          },
          error: {
            style: {
              background: "#FDE8E8",
            },
          },
        }}
      />
      <Suspense fallback={<div>Loading ...</div>}>
        {useRoutes(routes({ user, showSuccess, showInfo, showError }))}
      </Suspense>
    </>
  );
}

export default App;
